
import { Component } from 'vue-property-decorator';
import { CoreLoginPanel } from 'core-login-panel';
import { Getter } from 'vuex-class';
import { Route } from 'vue-router';
import i18n from '@/plugins/i18n';
import { ProcessEnvBaseModel, UserCredentialsModel } from '@/modules/core/core-types';
import { AuthService, TokenService } from '@/modules/core/core-auth';
import { ErrorHelper, LoggingHelper, LogLevel } from '@/modules/core/core-helpers';

import { GetterTypes as RouterGetterTypes } from '@/store/router/types';
import PwrVue from '@/components/PwrVue';
import { RoutesNames as AdminRoutesNames } from '@/router/admin/types';

const routerNamespace = 'router';

@Component({
  components: { CoreLoginPanel },
  metaInfo: {
    title: (i18n.t('views.login.title') as string) || 'Zaloguj się'
  }
})
export default class LoginView extends PwrVue {
  @Getter(RouterGetterTypes.GET_HISTORY, { namespace: routerNamespace }) routesHistory!: Route[];

  private errorMessage = '';
  private loading = false;

  get appName(): string {
    const nameTrans = this.$t('appName') as string;
    const nameEnv = (process.env as ProcessEnvBaseModel).VUE_APP_NAME;
    return nameTrans || nameEnv || 'Oferty Pracy';
  }

  private async mounted(): Promise<void> {
    this.loading = true;

    if (AuthService.wasSsoLogin(this.$route.query)) {
      await AuthService.afterSsoLogin(this.$route.query);
    }

    if (TokenService.isAccessTokenValid()) {
      await this.routesHelper.goToLastSessionRoute();
    }

    if (this.$route.query.reason) {
      this.errorMessage = (this.$route.query.reason as string) ?? '';
    }

    this.loading = false;
  }

  private async localLogin(credentials: UserCredentialsModel): Promise<void> {
    this.loading = true;

    this.errorMessage = '';

    try {
      await AuthService.login(credentials);
      await this.routesHelper.go(AdminRoutesNames.OFFERS_ADMINISTRATION);
    } catch (err: unknown) {
      const aError = ErrorHelper.getAxiosError(err);

      if (aError && aError.status) {
        this.errorMessage = aError.data.message;
      } else {
        this.errorMessage = this.$t('views.login.auth.errors.login').toString();
      }

      LoggingHelper.log(err, LogLevel.ERROR, ['app', 'LoginView.vue', 'localLogin']);
    }

    this.loading = false;
  }

  private ssoLogin(): void {
    AuthService.ssoLogin('login', 'login');
  }
}
